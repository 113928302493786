import {
  AccountTree,
  AccountTreeOutlined,
  AdminPanelSettings,
  AdminPanelSettingsOutlined,
  // Assessment,
  // AssessmentOutlined,
  // // AttachMoney,
  Badge,
  BadgeOutlined,
  // Build,
  // BuildOutlined,
  CarRental,
  CarRentalOutlined,
  // ConfirmationNumber,
  // ConfirmationNumberOutlined,
  Dashboard,
  DashboardOutlined,
  ElectricBike,
  ElectricBikeOutlined,
  EventAvailable,
  EventAvailableOutlined,
  Inventory,
  Inventory2Outlined,
  NearMe,
  NearMeOutlined,
  // EvStation,
  // Payments,
  // PaymentsOutlined,
  NoCrash,
  NoCrashOutlined,
  // NotificationAdd,
  // NotificationAddOutlined,
  PeopleAlt,
  PeopleAltOutlined,
  PinDrop,
  PinDropOutlined,
  Power,
  PowerOutlined,
  // PrecisionManufacturing,
  // PrecisionManufacturingOutlined,
  Sell,
  SellOutlined,
  SettingsRemote,
  SettingsRemoteOutlined,
  Speed,
  SpeedOutlined,
  SystemUpdateAlt,
  DeliveryDining,
  DeliveryDiningOutlined,
  Hub,
  HubOutlined,
  Build,
  BuildOutlined,
  ShoppingCart,
  ShoppingCartOutlined,
  PendingActions,
  PendingActionsOutlined,
  // Build,
  // BuildOutlined,
  Handyman,
  HandymanOutlined,
} from "@mui/icons-material";
import { lazy, ReactElement } from "react";
import { PageID } from "utils/constants";

// VMS pages:
// const Assembly = lazy(() => import("views/Retail/Vehicles"));
const RetailInventory = lazy(() => import("views/Retail/Inventory"));
const AssignedVehicles = lazy(() => import("views/Retail/AssignedVehicles"));
const Distribution = lazy(() => import("views/Retail/Distribution"));
const RetailOverview = lazy(() => import("views/Retail/Overview"));
const SoldVehicles = lazy(() => import("views/Retail/SoldVehicles"));
const RetailVehicles = lazy(() => import("views/Retail/Vehicles"));

// HOUSEKEEPING pages:
const ChargerTickets = lazy(() => import("views/Housekeeping/ChargerTickets"));
const SCM = lazy(() => import("views/Housekeeping/SCM"));
const OTA = lazy(() => import("views/Housekeeping/OTA"));
const Orders = lazy(() => import("views/Housekeeping/Orders"));
const Models = lazy(() => import("views/Housekeeping/Models"));
const Diagnostics = lazy(() => import("views/Housekeeping/Diagnostics"));

// FMS Pages:
const Geofence = lazy(() => import("views/Rental/Geofence"));
const RentalKYC = lazy(() => import("views/Rental/KYC"));
const Leases = lazy(() => import("views/Rental/Leases"));
const Hubs = lazy(() => import("views/Rental/Hubs"));
const RentalOverview = lazy(() => import("views/Rental/Overview"));
const RemoteControl = lazy(() => import("views/Rental/RemoteControl"));
const RentalTrips = lazy(() => import("views/Rental/Trips"));
const RentalUsers = lazy(() => import("views/Rental/Users"));
const RentalVendors = lazy(() => import("views/Rental/Vendors"));
const RentalVehicles = lazy(() => import("views/Rental/Vehicles"));

// Common Pages:
const Admin = lazy(() => import("views/Common/Admin"));
const Developer = lazy(() => import("views/Common/Developer"));
const Reports = lazy(() => import("views/Common/Reports"));
const Account = lazy(() => import("views/Account"));

// CMS Pages:
const Vendors = lazy(() => import("views/Charger/Vendors"));
const Bookings = lazy(() => import("views/Charger/Bookings"));
const Chargers = lazy(() => import("views/Charger/Chargers"));
const ChargerOverview = lazy(() => import("views/Charger/Overview"));
const ChargerKYC = lazy(() => import("views/Charger/KYC"));
const ChargerUsers = lazy(() => import("views/Charger/Users"));
const ServiceMaintenance = lazy(
  () => import("views/Charger/ServiceMaintenance"),
);
const Test = lazy(() => import("views/Charger/Test"));
const ChargerTrack = lazy(() => import("views/Charger/Track"));

export interface NavigationLink {
  name: string;
  path: string;
  icon: {
    default: JSX.Element;
    active: JSX.Element;
  };
}

type Route = {
  id: PageID;
  path: string;
  name: string;
  component: React.ComponentType<any>;
  description: string;
  hasNestedPages?: boolean;
};

type Routes = {
  [key: string]: Route[];
};

export const commonRoutes = [
  {
    path: "/account",
    name: "Account",
    component: Account,
    description: "Your Profile | Company Details",
  },
  {
    path: "/developer",
    name: "Developer",
    component: Developer,
    description: "Developer",
  },
];

export const routes: Routes = {
  retail: [
    {
      id: "retail:overview",
      path: "/overview",
      name: "Overview",
      component: RetailOverview,
      description: "",
    },
    {
      id: "retail:inventory",
      path: "/inventory",
      name: "Inventory",
      component: RetailInventory,
      description: "",
    },
    {
      id: "retail:vehicles",
      path: "/retail-vehicles",
      name: "Vehicles",
      component: RetailVehicles,
      description: "",
      hasNestedPages: true,
    },
    {
      id: "retail:distribution",
      path: "/distribution",
      name: "Distribution",
      component: Distribution,
      description: "",
    },
    {
      id: "retail:assigned_vehicles",
      path: "/assigned-vehicles",
      name: "Assigned Vehicles",
      component: AssignedVehicles,
      description: "",
      hasNestedPages: true,
    },
    {
      id: "retail:sold_vehicles",
      path: "/sold-vehicles",
      name: "Sold Vehicles",
      component: SoldVehicles,
      description: "",
      hasNestedPages: true,
    },
    // {
    //   id: "retail:invoices",
    //   path: "/invoices",
    //   name: "Invoices",
    //   component: RetailInvoices,
    //   description: "",
    // },
    {
      id: "retail:reports",
      path: "/reports",
      name: "Reports",
      component: Reports,
      description: "",
    },
    {
      id: "retail:admin",
      path: "/admin",
      name: "Admin",
      component: Admin,
      description: "",
    },
  ],
  rental: [
    {
      id: "rental:overview",
      path: "/overview",
      name: "Overview",
      component: RentalOverview,
      description: "",
    },
    {
      id: "rental:vehicles",
      path: "/vehicles",
      name: "Vehicles",
      component: RentalVehicles,
      description: "",
      hasNestedPages: true,
    },
    {
      id: "rental:hubs",
      path: "/hubs",
      name: "Hubs",
      component: Hubs,
      description: "",
    },
    {
      id: "rental:leases",
      path: "/leases",
      name: "Leases",
      component: Leases,
      description: "",
    },
    {
      id: "rental:trips",
      path: "/trips",
      name: "Trips",
      component: RentalTrips,
      description: "",
    },
    {
      id: "rental:users",
      path: "/users",
      name: "Users",
      component: RentalUsers,
      description: "",
    },
    {
      id: "rental:vendors",
      path: "/vendors",
      name: "Vendors",
      component: RentalVendors,
      description: "Vendors Stats | Vendor Insights",
    },
    {
      id: "rental:geofence",
      path: "/geofence",
      name: "Geofence",
      component: Geofence,
      description: "",
    },
    {
      id: "rental:kyc",
      path: "/kyc",
      name: "KYC",
      component: RentalKYC,
      description: "",
    },
    {
      id: "rental:reports",
      path: "/reports",
      name: "Reports",
      component: Reports,
      description: "",
    },
    {
      id: "rental:access_control",
      path: "/asset-control",
      name: "Asset Control",
      component: RemoteControl,
      description: "",
    },
    // {
    //   id: "rental:invoices",
    //   path: "/invoices",
    //   name: "Invoices",
    //   component: RentalInvoices,
    //   description: "",
    // },
    {
      id: "rental:admin",
      path: "/admin",
      name: "Admin",
      component: Admin,
      description: "",
    },
  ],
  charger: [
    {
      id: "charger:overview",
      path: "/overview",
      name: "Overview",
      component: ChargerOverview,
      description:
        "Performance Highlights | Top Locations | Top Hosts | New Users | Active Users | Map | Charger Availability | Operational Status | Charger Access",
    },
    // { path: "/settings", name: "Settings", component: Home },
    {
      id: "charger:chargers",
      path: "/chargers",
      name: "Chargers",
      component: Chargers,
      description: "Charger List | Booking List",
    },
    {
      id: "charger:bookings",
      path: "/bookings",
      name: "Bookings",
      component: Bookings,
      description:
        "Total Revenue | Total Bookings | Booking Duration | Energy Consumed | Bookings List",
    },
    {
      id: "charger:users",
      path: "/users",
      name: "Users",
      component: ChargerUsers,
      description:
        "Total Users | Active Users | Total New Users | Average Daily New Users | Users List | Hosts List | User Groups",
    },
    // {
    //   id: "charger:loadManagment",
    //   path: "/loadmanagment",
    //   name: "Load Managment",
    //   component: LoadManagment,
    //   description:
    //     "Total Bookings | TimeRange Stats | Weekly Stats | Pincode Stats",
    // },
    // {
    //   id: "charger:powerControl",
    //   path: "/powercontrol",
    //   name: "Power Control",
    //   component: PowerControl,
    //   description:
    //     "Charger Availability | Charger Assignment | Bookings vs Earnings | Energy Consumption",
    // },
    {
      id: "charger:vendors",
      path: "/vendors",
      name: "Vendors",
      component: Vendors,
      description:
        "Total Vendors | Total Assigned Chargers | Vendor Stats | Vendors List",
    },
    // {
    //   id: "charger:payments",
    //   path: "/payments",
    //   name: "Payments",
    //   component: Payments,
    //   description: "Payments Stats | Transactions | Earnings",
    // },
    // {
    //   id: "charger:subscriptions",
    //   path: "/subscriptions",
    //   name: "Subscriptions",
    //   component: Subscriptions,
    //   description: "Subscriptions",
    // },
    // {
    //   id: "charger:coupons",
    //   path: "/coupons",
    //   name: "Coupons",
    //   component: Coupons,
    //   description: "Coupons",
    // },
    {
      id: "charger:kyc",
      path: "/kyc",
      name: "KYC",
      component: ChargerKYC,
      description: "",
    },
    {
      id: "charger:reports",
      path: "/reports",
      name: "Reports",
      component: Reports,
      description:
        "Charger Reports | Vendor Reports | Previously Downloaded Reports",
    },
    // {
    //   id: "charger:notifications",
    //   name: "Notifications",
    //   path: "/notifications",
    //   component: Notifications,
    //   description: "Notifications",
    // },
    // {
    //   id: "charger:invoices",
    //   path: "/invoices",
    //   name: "Invoices",
    //   component: ChargerInvoices,
    //   description: "Invoices",
    // },
    {
      id: "charger:service_maintenance",
      path: "/service-maintenance",
      name: "Service & Maintenance",
      component: ServiceMaintenance,
      description:
        "Service & Maintenance Dashboard | Maintenance Logs | Service Requests",
    },
    {
      id: "charger:admin",
      path: "/admin",
      name: "Admin",
      component: Admin,
      description: "",
    },
    {
      id: "charger:test",
      path: "/test",
      name: "Test",
      component: Test,
      description: "",
    },

    {
      id: "charger:track",
      path: "/track",
      name: "Track",
      component: ChargerTrack,
      description: "",
    },
  ],
  // housekeeping: [
  //   {
  //     id: "housekeeping:chargerTickets",
  //     path: "/charger-tickets",
  //     name: "Charger Tickets",
  //     component: ChargerTickets,
  //     description:
  //       "Create Charger Tickets | Update Charger Tickets | Track Charger Tickets",
  //   },
  // ],
  housekeeping: [
    {
      id: "housekeeping:components",
      path: "/components",
      name: "Components",
      component: SCM,
      description: "",
    },
    {
      id: "housekeeping:ota_management",
      path: "/ota",
      name: "Files",
      component: OTA,
      description: "",
    },
    {
      id: "housekeeping:charger_tickets",
      path: "/charger-tickets",
      name: "Charger Tickets",
      component: ChargerTickets,
      description:
        "Create Charger Tickets | Update Charger Tickets | Track Charger Tickets",
    },
    {
      id: "housekeeping:diagnostics",
      path: "/diagnostics",
      name: "DIAGNOSTICS",
      component: Diagnostics,
      description: "",
    },
    {
      id: "housekeeping:models",
      path: "/housekeeping-models",
      name: "Models",
      component: Models,
      description: "",
    },
    {
      id: "housekeeping:orders",
      path: "/orders",
      name: "Orders",
      component: Orders,
      description: "Bolt.Earth Orders",
    },
  ],
};

type NavLink = {
  id: PageID;
  name: string;
  path: string;
  icon: {
    active: ReactElement;
    default: ReactElement;
  };
};

export const navigationLinks: { [key: string]: NavLink[] } = {
  retail: [
    {
      id: "retail:overview",
      name: "Overview",
      path: "/overview",
      icon: { active: <Dashboard />, default: <DashboardOutlined /> },
    },
    {
      id: "retail:inventory",
      name: "Inventory",
      path: "/inventory",
      icon: { active: <Inventory />, default: <Inventory2Outlined /> },
    },
    {
      id: "retail:assigned_vehicles",
      name: "Assigned Vehicles",
      path: "/assigned-vehicles",
      icon: { active: <NoCrash />, default: <NoCrashOutlined /> },
    },
    {
      id: "retail:vehicles",
      name: "Vehicles",
      path: "/retail-vehicles",
      icon: {
        active: <DeliveryDining />,
        default: <DeliveryDiningOutlined />,
      },
    },
    {
      id: "retail:distribution",
      name: "Distribution",
      path: "/distribution",
      icon: { active: <AccountTree />, default: <AccountTreeOutlined /> },
    },
    {
      id: "retail:sold_vehicles",
      name: "Sold Vehicles",
      path: "/sold-vehicles",
      icon: { active: <Sell />, default: <SellOutlined /> },
    },
    // {
    //   id: "retail:invoices",
    //   name: "Invoices",
    //   path: "/invoices",
    //   icon: { active: <Description />, default: <DescriptionOutlined /> },
    // },
    {
      id: "retail:reports",
      name: "Reports",
      path: "/reports",
      icon: { active: <PendingActions />, default: <PendingActionsOutlined /> },
    },
    {
      id: "retail:admin",
      name: "Admin",
      path: "/admin",
      icon: {
        active: <AdminPanelSettings />,
        default: <AdminPanelSettingsOutlined />,
      },
    },
  ],
  rental: [
    {
      id: "rental:overview",
      name: "Overview",
      path: "/overview",
      icon: { active: <Dashboard />, default: <DashboardOutlined /> },
    },
    {
      id: "rental:vehicles",
      name: "Vehicles",
      path: "/vehicles",
      icon: {
        active: <ElectricBike />,
        default: <ElectricBikeOutlined />,
      },
    },
    {
      id: "rental:hubs",
      name: "Hubs",
      path: "/hubs",
      icon: { active: <Hub />, default: <HubOutlined /> },
    },
    {
      id: "rental:leases",
      name: "Leases",
      path: "/leases",
      icon: { active: <CarRental />, default: <CarRentalOutlined /> },
    },
    {
      id: "rental:trips",
      name: "Trips",
      path: "/trips",
      icon: { active: <NearMe />, default: <NearMeOutlined /> },
    },
    {
      id: "rental:users",
      name: "Users",
      path: "/users",
      icon: { active: <PeopleAlt />, default: <PeopleAltOutlined /> },
    },
    {
      id: "rental:vendors",
      name: "Vendors",
      path: "/vendors",
      icon: { active: <AccountTree />, default: <AccountTreeOutlined /> },
    },
    {
      id: "rental:geofence",
      name: "Geofence",
      path: "/geofence",
      icon: { active: <PinDrop />, default: <PinDropOutlined /> },
    },
    {
      id: "rental:kyc",
      name: "KYC",
      path: "/kyc",
      icon: { active: <Badge />, default: <BadgeOutlined /> },
    },
    {
      id: "rental:reports",
      name: "Reports",
      path: "/reports",
      icon: { active: <PendingActions />, default: <PendingActionsOutlined /> },
    },
    {
      id: "rental:access_control",
      name: "Asset Control",
      path: "/asset-control",
      icon: {
        active: <SettingsRemote />,
        default: <SettingsRemoteOutlined />,
      },
    },
    // {
    //   id: "rental:invoices",
    //   name: "Invoices",
    //   path: "/invoices",
    //   icon: { active: <Description />, default: <DescriptionOutlined /> },
    // },
    {
      id: "rental:admin",
      name: "Admin",
      path: "/admin",
      icon: {
        active: <AdminPanelSettings />,
        default: <AdminPanelSettingsOutlined />,
      },
    },
  ],
  charger: [
    {
      id: "charger:overview",
      name: "Overview",
      path: "/overview",
      icon: { active: <Dashboard />, default: <DashboardOutlined /> },
    },
    {
      id: "charger:chargers",
      name: "Chargers",
      path: "/chargers",
      icon: { active: <Power />, default: <PowerOutlined /> },
    },
    // {
    //   id: "charger:loadManagment",
    //   name: "Load Managment",
    //   path: "/loadmanagment",
    //   icon: { active: <EvStation />, default: <EvStation /> },
    // },
    {
      id: "charger:bookings",
      name: "Bookings",
      path: "/bookings",
      icon: { active: <EventAvailable />, default: <EventAvailableOutlined /> },
    },
    // {
    //   id: "charger:payments",
    //   name: "Payments",
    //   path: "/payments",
    //   icon: { active: <Payments />, default: <PaymentsOutlined /> },
    // },
    {
      id: "charger:users",
      name: "Users",
      path: "/users",
      icon: { active: <PeopleAlt />, default: <PeopleAltOutlined /> },
    },
    {
      id: "charger:vendors",
      name: "Vendors",
      path: "/vendors",
      icon: { active: <AccountTree />, default: <AccountTreeOutlined /> },
    },
    // {
    //   id: "charger:subscriptions",
    //   name: "Subscriptions",
    //   path: "/subscriptions",
    //   icon: { active: <AttachMoney />, default: <AttachMoney /> },
    // },
    // {
    //   id: "charger:coupons",
    //   name: "Coupons",
    //   path: "/coupons",
    //   icon: {
    //     active: <ConfirmationNumber />,
    //     default: <ConfirmationNumberOutlined />,
    //   },
    // },
    {
      id: "charger:kyc",
      name: "KYC",
      path: "/kyc",
      icon: { active: <Badge />, default: <BadgeOutlined /> },
    },
    {
      id: "charger:reports",
      name: "Reports",
      path: "/reports",
      icon: { active: <PendingActions />, default: <PendingActionsOutlined /> },
    },
    // {
    //   id: "charger:notifications",
    //   name: "Notifications",
    //   path: "/notifications",
    //   icon: {
    //     active: <NotificationAdd />,
    //     default: <NotificationAddOutlined />,
    //   },
    // },
    // {
    //   id: "charger:invoices",
    //   name: "Invoices",
    //   path: "/invoices",
    //   icon: { active: <Description />, default: <DescriptionOutlined /> },
    // },
    {
      id: "charger:service_maintenance",
      name: "Service & Maintenance",
      path: "/service-maintenance",
      icon: { active: <Handyman />, default: <HandymanOutlined /> },
    },
    {
      id: "charger:admin",
      name: "Admin",
      path: "/admin",
      icon: {
        active: <AdminPanelSettings />,
        default: <AdminPanelSettingsOutlined />,
      },
    },
  ],

  housekeeping: [
    {
      id: "housekeeping:components",
      name: "Components",
      path: "/components",
      icon: { active: <Speed />, default: <SpeedOutlined /> },
    },
    {
      id: "housekeeping:ota_management",
      name: "Files",
      path: "/ota",
      icon: {
        active: <SystemUpdateAlt sx={{ transform: "rotate(180deg)" }} />,
        default: <SystemUpdateAlt sx={{ transform: "rotate(180deg)" }} />,
      },
    },
    {
      id: "housekeeping:charger_tickets",
      name: "Charger Tickets",
      path: "/charger-tickets",
      icon: { active: <Power />, default: <PowerOutlined /> },
    },
    {
      id: "housekeeping:diagnostics",
      name: "Diagnostics",
      path: "/diagnostics",
      icon: { active: <Build />, default: <BuildOutlined /> },
    },
    {
      id: "housekeeping:models",
      name: "Models",
      path: "/housekeeping-models",
      icon: {
        active: <DeliveryDining />,
        default: <DeliveryDiningOutlined />,
      },
    },
    {
      id: "housekeeping:orders",
      name: "Orders",
      path: "/orders",
      icon: { active: <ShoppingCart />, default: <ShoppingCartOutlined /> },
    },
  ],
};
