import { Box, Drawer, Typography } from "@mui/material";
import InfoDrawer from "components/InfoDrawer";
import React, { Suspense, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { match, Redirect, Route, Switch } from "react-router";
import { useLocation } from "react-router-dom";
import { commonRoutes, routes } from "routes";
import { getDarkModePreference, getPermissions, GlobalState } from "utils";
import AppBar from "./AppBar";
import { drawerWidth } from "./constants";
import NavigationDrawer from "./NavigationDrawer";
import { WarningAmber } from "@mui/icons-material";
import { CRM_EMAILS } from "utils/constants";
import CircularLoader from "components/CircularLoader";

interface Props {
  match: match;
}

const DashboardLayout: React.FC<Props> = ({ match }) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state),
  );
  const { activeSection, drawer, company, user } = useSelector(
    (state: GlobalState) => state.global,
  );

  const containerRef = useRef(null);

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const location = useLocation();
  const navProps = {
    isDarkMode,
    location: location.pathname,
    handleClose: handleDrawerToggle,
  };

  const noPadding =
    (activeSection === "charger" &&
      [
        "/overview",
        "/chargers",
        "/bookings",
        "/vendors",
        "/users",
        "/coupons",
        "/kyc",
        "/service-maintenance",
        "/admin",
      ].includes(location.pathname)) ||
    (activeSection === "retail" &&
      ["/assembly", "/retail-vehicles", "/scm", "/oem"].includes(
        location.pathname,
      )) ||
    (activeSection === "rental" &&
      ["/", "/vehicles", "/leases", "/trips"].includes(location.pathname)) ||
    ["/admin", "/reports"].includes(location.pathname);

  let userEmail = useSelector((state: GlobalState) => state.global.user.email);

  const isBounce = userEmail === "srikhantan.s@bounceshare.com";
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "grid",
        gridTemplateColumns: { xs: "0 1fr", md: `${drawerWidth}px 1fr` },
        overflow: "hidden",
      }}
    >
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <nav style={{ height: "100vh" }}>
        <Drawer
          sx={{ display: { xs: "block", md: "none" } }}
          container={window?.document.body || undefined}
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <NavigationDrawer {...navProps} />
        </Drawer>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <NavigationDrawer {...navProps} />
        </Box>
      </nav>
      <Box
        sx={{
          width: 1,
          height: 1,
          minWidth: 0,
          minHeight: 0,
          display: "grid",
          gridTemplateRows: "min-content 1fr",
        }}
      >
        <AppBar
          v2={activeSection === "charger"}
          handleDrawerToggle={handleDrawerToggle}
          pageTitle={
            routes[activeSection || "charger"]?.find(
              (route: any) => route.path === location.pathname,
            )?.name || "Dashboard"
          }
        />
        <Box
          sx={{
            position: "relative",
            display: "grid",
            gridTemplateColumns: "minmax(0, 1fr) auto",
            width: 1,
            height: 1,
            overflow: "auto",
          }}
        >
          <Suspense
            fallback={
              <Box
                sx={{
                  width: 1,
                  height: 1,
                  backgroundColor: (theme) =>
                    theme.customColors.background.lightGrey,
                  py: 4,
                }}
              >
                <CircularLoader />
              </Box>
            }
          >
            <Box
              id="content"
              ref={containerRef}
              key={location.pathname}
              sx={{
                ...(noPadding
                  ? {}
                  : {
                      p: { xs: 1.5, md: 4 },
                      pt: { xs: 2, md: 3 },
                    }),
                width: 1,
                height: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
                visibility: {
                  xs: drawer.open ? "hidden" : "visible",
                  md: "visible",
                },
              }}
            >
              {(!user.permissionMatrix || !user.permissionMatrix.length) && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <WarningAmber fontSize="large" color="warning" />
                    <Typography variant="body2" mt={2}>
                      You do not have the appropriate permissions. Please
                      contact your administrator.
                    </Typography>
                  </Box>
                </Box>
              )}
              <Switch>
                {activeSection &&
                  routes[activeSection]
                    .filter((route) => {
                      let { canRead } = getPermissions(route.id);

                      const isOEM = company?.type === "OEM";

                      if (
                        isOEM &&
                        ["retail:assignedVehicles"].includes(route.id)
                      )
                        return false;
                      if (!isOEM && ["retail:assembly"].includes(route.id))
                        return false;

                      if (
                        isCUGMonitorAccount &&
                        ![
                          "charger:chargers",
                          "charger:users",
                          "charger:reports",
                          "charger:service_maintenance",
                        ].includes(route.id)
                      )
                        return false;

                      if (
                        [
                          "adi.setiakarsa@alvaauto.com",
                          "redya.febriyanto@alvaauto.com",
                        ].includes(user.email) &&
                        ![
                          "Overview",
                          "Chargers",
                          "Bookings",
                          "Reports",
                          "Service & Maintenance",
                        ].includes(route.name)
                      )
                        return false;

                      if (
                        ["srikhantan.s@bounceshare.com"].includes(user.email) &&
                        !["Vehicles", "Trips", "Reports"].includes(route.name)
                      )
                        return false;

                      if (
                        company?.name === "testTVS" &&
                        [
                          "Chargers",
                          "Vendors",
                          "Subscriptions",
                          "Coupons",
                          "Invoices",
                          "KYC",
                          "Admin",
                        ].includes(route.name)
                      )
                        return false;

                      // Check if user has CRM email for service maintenance access
                      if (route.id === "charger:service_maintenance") {
                        return CRM_EMAILS.includes(user?.email);
                      }

                      // return route.id.includes("admin") ? isSuperAdmin : canRead;
                      return canRead;
                    })
                    .map((route, i) => (
                      <Route
                        key={i}
                        exact={!route.hasNestedPages}
                        path={route.path}
                        render={(props) => {
                          const Component =
                            route.component as React.ElementType;
                          return (
                            <Component {...props} containerRef={containerRef} />
                          );
                        }}
                      />
                    ))}
                {commonRoutes.map((route, i) => (
                  <Route
                    exact
                    path={route.path}
                    key={i}
                    render={(props) => {
                      const Component = route.component as React.ElementType;
                      return (
                        <Component {...props} containerRef={containerRef} />
                      );
                    }}
                  />
                ))}
                <Redirect
                  to={
                    isCUGMonitorAccount
                      ? "chargers"
                      : isBounce
                        ? "/vehicles"
                        : activeSection === "housekeeping"
                          ? "/components"
                          : "/overview"
                  }
                />
              </Switch>
            </Box>
          </Suspense>
          <InfoDrawer />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
