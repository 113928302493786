import { AUTH_URL } from "./constants";
import storageManager from "./storageManager";
import store from "store";
import { logout } from "actions";
import { snackbar } from "utils";

const twentyNineDaysInMilliseconds = 1000 * 60 * 60 * 24 * 29;

export const revosLogin = async (user: any) => {
  return await fetch(`${AUTH_URL}/company/v2/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  })
    .then((res) => res.json())
    .then(async (response) => {
      if (response?.status !== 200) return response;
      const boltToken = response.data?.token;
      const boltTokenExpirationTime = new Date(
        response.data?.authTokenExpiryDate,
      ).getTime();
      const refreshToken = response.data?.refreshToken;
      const companyToken = response.data?.user?.company?.token;

      storageManager.set("boltToken", boltToken);
      storageManager.set("boltTokenExpirationTime", boltTokenExpirationTime);
      storageManager.set("refreshToken", refreshToken);
      storageManager.set("companyToken", companyToken);

      return response;
    });
};

export const revosUserSignUp = async (body: any, code: any) => {
  return fetch(`${AUTH_URL}/company/register/user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(code ? { code } : {}),
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then(async (response) => {
      if (response?.status >= 400) return response;
      let boltToken = response.data?.token;

      storageManager.set(
        "boltTokenExpirationTime",
        new Date().getTime() + twentyNineDaysInMilliseconds,
      );
      storageManager.set("boltToken", boltToken);

      return response;
    });
};

export const getBoltToken = async () => {
  if (
    new Date().getTime() <
    parseInt(storageManager.get("boltTokenExpirationTime") || "")
  ) {
    return storageManager.get("boltToken");
  } else {
    let newBoltToken = await refreshToken();
    return newBoltToken;
  }
};

export async function refreshToken() {
  const refreshToken =
    storageManager.get("refreshToken") || storageManager.get("boltToken");

  if (!refreshToken) {
    onTokenFail();
    return;
  }

  return fetch(`${AUTH_URL}/refreshToken`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refreshToken }),
  })
    .then((res) => res.json())
    .then((response) => {
      if (response.authToken) {
        const boltToken = response.authToken;
        const boltTokenExpirationTime = new Date(
          response.authTokenExpiryDate,
        ).getTime();
        const refreshToken = response.refreshToken;

        storageManager.set("boltToken", boltToken);
        storageManager.set("boltTokenExpirationTime", boltTokenExpirationTime);
        storageManager.set("refreshToken", refreshToken);

        return boltToken;
      } else {
        onTokenFail();
      }
    })
    .catch((err) => {
      console.error(err);
      onTokenFail();
    });
}

export function onTokenFail(msg?: string) {
  snackbar.error(msg || "An error occurred. Please sign in again.");
  console.log("onTokenFail");
  store.dispatch(logout());
}

export function resetPasswordRequest(email: any) {
  return fetch(
    `${AUTH_URL}/company/forgot-password?email=${encodeURIComponent(email)}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
    .then((res) => res.json())
    .then((response) => {
      if (response.status && response.status >= 400) {
        return {
          code: response.status.toString(),
          message:
            response.message || "Error occurred. Please try again later.",
        };
      }
      return "Password reset link sent on your email.";
    })
    .catch(() => {
      return {
        code: "500",
        message: "Error occurred. Please try again later.",
      };
    });
}
